import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">


<path d="M0 5400 l0 -5400 5400 0 5400 0 0 5400 0 5400 -5400 0 -5400 0 0
-5400z m5660 2985 c109 -20 276 -62 315 -79 17 -7 64 -26 105 -42 41 -16 77
-31 80 -34 3 -3 23 -14 45 -25 22 -10 53 -28 70 -40 16 -11 40 -26 52 -32 59
-33 72 -41 128 -83 51 -38 60 -49 57 -72 -3 -24 0 -28 21 -28 27 0 40 -19 27
-41 -14 -21 -70 37 -70 73 0 17 -7 31 -20 38 -14 7 -20 7 -20 0 0 -5 -7 -10
-16 -10 -11 0 -14 6 -8 22 6 21 6 21 -11 5 -17 -17 -17 -19 1 -32 12 -8 23
-10 29 -4 5 5 16 9 25 8 10 0 11 -2 2 -6 -6 -2 -9 -10 -6 -15 4 -7 2 -8 -4 -4
-7 4 -12 2 -12 -4 0 -6 -6 -8 -12 -4 -7 4 2 -7 21 -23 l35 -30 -38 -1 c-20 -1
-34 3 -31 8 3 5 -4 11 -15 15 -13 4 -20 15 -20 31 l0 24 -1017 0 c-560 0
-1034 -3 -1055 -6 -48 -8 -59 -40 -17 -49 40 -9 37 -25 -4 -25 -21 0 -41 8
-52 20 -10 11 -23 20 -29 20 -6 0 -17 -9 -24 -20 -14 -23 -44 -28 -36 -6 3 8
1 17 -4 20 -6 3 -8 15 -5 26 3 11 0 20 -6 20 -6 0 -11 -6 -11 -13 0 -8 -10
-19 -22 -25 l-23 -11 23 0 c23 -1 29 -15 11 -27 -6 -3 -16 -1 -22 5 -17 17
-22 13 -25 -15 -1 -14 -7 -34 -12 -45 -11 -20 -30 -26 -30 -9 0 6 5 10 10 10
6 0 10 11 10 25 0 14 5 25 12 25 6 0 8 3 4 7 -4 4 -18 -6 -31 -22 -13 -17 -24
-25 -25 -19 0 13 73 81 149 137 31 23 58 44 61 47 3 4 23 17 45 30 22 13 42
26 45 29 6 7 37 25 110 65 30 16 58 34 61 39 4 5 25 10 47 11 22 0 36 4 29 8
-11 7 -5 10 78 39 17 6 39 15 50 19 34 14 108 29 117 24 4 -3 8 -1 8 4 0 5 12
12 28 15 15 4 61 14 102 23 41 9 111 20 155 24 124 13 467 4 565 -15z m916
-430 c21 -19 34 -34 27 -35 -7 0 -20 8 -30 18 -10 11 -26 22 -35 25 -18 6 -25
27 -10 27 5 0 26 -16 48 -35z m-1878 -32 c-10 -2 -28 -2 -40 0 -13 2 -5 4 17
4 22 1 32 -1 23 -4z m722 -3 c-13 -8 -12 -10 5 -11 11 -1 0 -5 -25 -10 -59
-11 -495 -10 -540 0 -23 6 41 10 190 11 124 1 239 6 255 10 46 12 133 12 115
0z m173 3 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m680 0 c-7
-2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m80 0 c-7 -2 -19 -2 -25 0
-7 3 -2 5 12 5 14 0 19 -2 13 -5z m-1602 -29 c-22 -28 -27 -50 -12 -56 4 -1
-7 -13 -26 -26 -19 -12 -33 -17 -31 -10 3 7 13 18 24 23 13 7 18 18 16 38 -4
31 12 57 35 57 11 0 10 -6 -6 -26z m-756 -25 c-4 -13 -3 -19 2 -16 6 4 8 -5 4
-21 -5 -25 -6 -26 -9 -4 -3 18 -8 21 -24 16 -20 -6 -19 -5 3 19 30 32 32 32
24 6z m827 14 c-13 -3 -19 -9 -16 -15 4 -6 2 -8 -3 -5 -6 4 -16 0 -23 -9 -10
-12 -15 -12 -21 -2 -13 20 11 38 50 37 23 -1 28 -3 13 -6z m585 1 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-142 -17 c33 -2 -39 -4 -160 -5
-121 -1 -225 2 -232 6 -10 6 249 6 392 -1z m1244 -32 c20 6 44 -30 41 -61 -4
-31 -1 -34 24 -24 18 7 28 5 40 -9 23 -25 20 -37 -5 -15 -15 13 -24 16 -37 8
-13 -8 -21 -6 -34 8 -10 10 -14 18 -9 18 12 0 5 40 -9 54 -7 7 -17 7 -31 -2
-12 -8 -18 -9 -15 -4 6 10 -48 22 -101 22 -17 0 -35 -4 -38 -10 -3 -5 -12 -10
-18 -10 -7 0 -3 8 8 16 15 12 35 14 84 10 50 -4 62 -3 53 6 -7 7 -12 16 -12
20 0 4 9 -1 21 -13 12 -12 27 -18 38 -14z m157 8 c-7 -7 -26 7 -26 19 0 6 6 6
15 -2 9 -7 13 -15 11 -17z m-1233 10 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17
0 24 -2 18 -5z m-765 -33 c-14 -22 -24 -19 -15 5 4 8 11 15 17 15 6 0 6 -7 -2
-20z m820 10 c-2 -5 2 -9 7 -7 6 1 9 5 8 10 -2 4 1 7 6 7 5 0 9 -16 8 -35 l-1
-36 -27 28 c-17 19 -22 30 -15 35 15 10 16 10 14 -2z m812 6 c0 -11 -19 -15
-25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z m-2330 -16 c0 -12 -28 -25 -36 -17 -9
9 6 27 22 27 8 0 14 -5 14 -10z m2300 -34 c0 -17 2 -18 10 -6 5 8 10 11 10 5
-1 -5 -7 -18 -14 -28 -12 -16 -14 -16 -20 -1 -3 9 -4 20 -1 24 3 5 1 11 -5 15
-5 3 -10 13 -10 22 0 13 3 13 15 3 8 -7 15 -22 15 -34z m-2350 15 c0 -5 -7
-17 -16 -25 -8 -9 -12 -16 -7 -16 5 0 14 5 21 12 8 8 12 8 12 1 0 -14 -26 -24
-44 -17 -12 5 -11 10 7 29 25 27 27 28 27 16z m2595 -12 c11 -17 -1 -21 -15
-4 -8 9 -8 15 -2 15 6 0 14 -5 17 -11z m-2520 -9 c-3 -5 -11 -10 -16 -10 -6 0
-7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m2750 -19 c-6 -5 -25 10 -25 20 0
5 6 4 14 -3 8 -7 12 -15 11 -17z m-2087 -16 c-6 -14 -13 -23 -15 -21 -5 5 15
46 22 46 3 0 -1 -11 -7 -25z m895 -15 c3 -11 1 -23 -4 -26 -5 -3 -9 1 -9 10 0
8 -3 22 -7 32 -4 11 -3 15 4 11 6 -4 13 -16 16 -27z m27 20 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-1710 -10 c0 -5 -5
-10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m2874 -21 c-10
-16 -24 -9 -24 12 0 18 2 19 15 9 8 -7 12 -16 9 -21z m-2847 -14 c-23 -22 -33
-18 -12 5 10 11 20 18 23 16 2 -3 -3 -12 -11 -21z m1487 3 c23 -21 24 -24 10
-37 -14 -12 -15 -11 -9 8 4 14 2 21 -7 21 -7 0 -123 1 -258 2 l-245 2 245 6
245 5 -270 5 -270 5 267 3 c264 2 267 2 292 -20z m1441 -8 l20 -25 -22 19
c-13 11 -23 24 -23 29 0 6 1 8 3 6 1 -2 11 -15 22 -29z m-2022 -5 c-12 -8 -24
-28 -26 -43 -4 -27 -4 -27 -6 4 -1 32 14 54 38 54 11 0 9 -4 -6 -15z m405 -12
c-49 -2 -127 -2 -175 0 -49 1 -9 3 87 3 96 0 136 -2 88 -3z m162 -3 c0 -5 -2
-10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-1575 -40
c-10 -11 -20 -18 -23 -15 -3 3 3 14 13 25 10 11 20 18 23 15 3 -3 -3 -14 -13
-25z m2905 15 c20 -24 6 -28 -15 -5 -10 11 -13 20 -8 20 6 0 16 -7 23 -15z
m161 -2 c13 -16 12 -17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z m-1444 -40
c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-620 -5 c-3 -7 -5 -2
-5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m2087 7 c-5 -11 -2 -13 12 -8 16 6 17
5 4 -9 -13 -13 -16 -14 -23 -2 -4 8 -5 19 -2 24 9 15 15 12 9 -5z m51 -55 c3
-5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-5425
-3319 c5 -11 10 -42 10 -69 0 -27 3 -78 8 -113 6 -59 7 -55 12 66 l5 130 214
3 c165 2 216 -1 223 -10 4 -7 8 -325 8 -707 0 -382 1 -695 3 -697 3 -4 53 312
83 521 7 50 17 122 23 160 6 39 20 131 31 205 47 321 80 514 88 522 4 4 138 7
297 6 l289 -3 8 -35 c4 -19 14 -84 23 -145 9 -60 24 -162 34 -225 10 -63 52
-335 92 -605 l74 -490 3 753 2 752 308 -2 307 -3 7 -55 c4 -30 11 -95 15 -145
l8 -90 5 145 5 145 209 3 c139 2 213 -1 220 -8 8 -8 12 -228 14 -702 1 -381 4
-693 5 -695 2 -1 11 52 21 120 9 67 22 147 27 177 9 56 131 864 150 1000 6 41
14 82 19 90 6 13 50 15 301 15 l294 0 7 -50 c11 -67 28 -178 38 -245 17 -106
30 -196 49 -325 21 -140 61 -404 85 -550 l14 -90 1 138 c1 76 6 147 11 158 10
18 23 19 188 19 108 0 172 4 163 9 -8 5 -63 29 -123 52 -122 49 -176 92 -212
169 -19 41 -21 63 -21 275 l0 230 28 57 c31 62 77 106 144 138 39 18 63 20
271 20 267 0 294 -6 367 -78 83 -81 89 -106 90 -334 0 -108 -4 -200 -8 -205
-5 -4 -94 -8 -198 -8 -104 0 -189 -3 -189 -6 0 -3 51 -25 113 -48 165 -64 215
-100 258 -188 23 -46 24 -56 24 -273 0 -214 -1 -227 -23 -267 -34 -65 -78
-109 -134 -137 -51 -26 -54 -26 -293 -26 -239 0 -242 0 -292 26 -47 23 -99 71
-130 120 -10 16 -11 10 -6 -36 4 -30 7 -66 7 -80 l1 -25 -220 0 -220 0 -8 60
c-4 33 -10 79 -13 103 l-5 42 -65 0 -64 0 -1 -47 c0 -27 -6 -73 -12 -103 l-12
-55 -523 -3 c-442 -2 -523 0 -528 12 -3 8 -11 79 -18 157 l-13 144 -2 -157 -1
-158 -440 0 -440 0 -4 23 c-3 12 -8 49 -11 82 -11 108 -9 105 -75 105 -66 0
-64 2 -75 -95 -3 -33 -8 -72 -11 -88 l-6 -27 -526 2 -527 3 -11 105 -11 105
-2 -95 c-1 -52 -5 -101 -9 -108 -7 -9 -58 -12 -223 -10 l-214 3 -3 770 c-1
424 0 776 3 783 3 9 74 12 304 12 287 0 301 -1 311 -19z m5487 4 c63 -19 130
-77 163 -141 l25 -49 5 -510 c3 -302 8 -490 13 -460 4 28 17 111 28 185 19
126 47 310 69 458 6 34 15 92 20 130 6 37 15 92 20 122 4 30 14 93 20 140 7
47 16 96 19 110 l8 25 293 0 294 0 7 -30 c3 -16 12 -68 18 -115 7 -47 17 -110
22 -140 5 -30 18 -118 29 -195 25 -166 63 -417 90 -587 48 -304 71 -472 66
-484 -5 -12 -42 -14 -223 -12 l-218 3 -3 35 c-1 19 -7 65 -13 103 l-11 67 -58
0 c-67 0 -65 3 -75 -105 -11 -116 14 -105 -231 -105 -245 0 -232 -5 -217 89
l9 57 -61 -61 c-45 -46 -72 -65 -105 -74 -32 -8 -140 -11 -375 -9 l-330 3 -3
770 c-1 424 0 776 3 783 7 19 634 17 702 -3z m1958 -7 c12 -51 21 -233 12
-245 -7 -9 -45 -13 -128 -13 l-119 0 0 -645 0 -645 -220 0 -220 0 0 645 0 645
-119 0 c-84 0 -122 4 -129 13 -10 12 1 207 14 250 5 16 35 17 454 17 l450 0 5
-22z m672 -43 c6 -33 26 -154 43 -270 17 -115 37 -253 45 -305 8 -52 31 -201
51 -330 19 -129 45 -293 56 -365 11 -71 20 -152 21 -180 l2 -50 -217 -3 c-247
-3 -222 -14 -233 103 -10 108 -8 105 -75 105 -66 0 -64 3 -75 -105 -12 -118
15 -105 -231 -105 -179 0 -216 2 -220 15 -3 8 1 58 10 112 8 54 32 206 51 338
19 132 44 294 55 360 11 66 27 170 35 230 9 61 20 135 25 165 5 30 18 117 29
193 12 77 24 143 27 148 3 5 127 8 297 7 l292 -3 12 -60z"/>
<path d="M5688 8333 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5838 8323 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6020 8260 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M6068 8243 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6190 8180 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6315 8109 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M4288 8083 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4205 8040 c-4 -6 -17 -15 -30 -20 -17 -7 -22 -14 -18 -29 6 -23 33
-27 64 -11 29 16 23 27 -12 22 -22 -3 -30 -1 -25 7 4 6 13 11 21 11 18 0 28
17 16 24 -5 4 -13 1 -16 -4z"/>
<path d="M5320 7910 l-45 -7 40 -1 c22 0 49 3 60 8 23 10 9 10 -55 0z"/>
<path d="M5390 4220 c-12 -8 -10 -10 8 -10 13 0 20 4 17 10 -7 12 -6 12 -25 0z"/>
<path d="M1448 4213 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2728 4213 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4080 4210 c0 -6 -66 -11 -162 -13 l-163 -3 163 -2 162 -2 0 -95 c0
-88 -1 -95 -20 -95 -16 0 -20 7 -20 35 0 27 -4 35 -17 35 -10 0 -60 1 -111 3
l-92 2 -1 -40 -2 -40 -6 39 c-8 52 -31 60 -31 11 0 -20 -6 -42 -13 -50 -12
-11 -13 -5 -9 36 4 43 2 49 -14 49 -21 0 -20 25 -23 -487 0 -150 -5 -273 -10
-273 -5 0 -14 60 -20 133 -18 204 -33 359 -37 392 -3 17 -7 72 -11 123 -4 68
-9 92 -19 92 -8 0 -14 6 -14 13 0 9 -9 12 -29 9 -23 -3 -30 -10 -35 -35 -4
-20 -2 -34 6 -39 10 -6 9 -8 -3 -8 -9 0 -20 7 -23 16 -9 23 -7 36 4 29 6 -3
10 2 10 12 0 17 -12 18 -170 15 l-170 -3 0 -34 c0 -50 -28 -47 -32 3 -4 47
-21 44 -29 -5 l-6 -38 -2 43 c0 23 -5 42 -11 42 -5 0 -11 19 -12 43 -1 23 -5
-32 -8 -123 l-6 -165 -2 168 c-1 109 -6 166 -12 162 -7 -4 -10 -257 -10 -726
l0 -719 190 0 190 0 0 454 c0 293 4 457 10 461 6 3 10 5 11 3 0 -4 18 -230 24
-318 5 -78 16 -214 36 -457 l12 -143 280 0 280 0 -7 733 c-4 402 -7 740 -7
750 0 9 -2 17 -5 17 -2 0 -4 -4 -4 -10z m-83 -192 c-2 -13 -4 -5 -4 17 -1 22
1 32 4 23 2 -10 2 -28 0 -40z m-390 -20 c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23
2 -10 2 -28 0 -40z m20 10 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m110 -70 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m-110
-5 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z m120 -75 c-2 -13
-4 -5 -4 17 -1 22 1 32 4 23 2 -10 2 -28 0 -40z"/>
<path d="M4390 4208 c0 -17 18 -18 258 -16 l257 3 -250 2 c-184 3 -251 7 -257
16 -5 8 -8 7 -8 -5z"/>
<path d="M5773 4213 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M5319 4190 c-13 -10 -18 -19 -11 -20 6 0 12 4 12 9 0 5 12 14 28 19
15 6 20 11 11 11 -9 1 -27 -8 -40 -19z"/>
<path d="M5850 4203 c8 -3 22 -11 30 -18 16 -13 50 -27 50 -21 0 7 -71 46 -83
45 -7 0 -5 -3 3 -6z"/>
<path d="M941 4184 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1078 4193 c67 -2 177 -2 245 0 67 1 12 3 -123 3 -135 0 -190 -2
-122 -3z"/>
<path d="M1683 4193 c48 -2 125 -2 170 0 45 1 6 3 -88 3 -93 0 -130 -2 -82 -3z"/>
<path d="M2358 4193 c67 -2 177 -2 245 0 67 1 12 3 -123 3 -135 0 -190 -2
-122 -3z"/>
<path d="M3238 4193 c67 -2 177 -2 245 0 67 1 12 3 -123 3 -135 0 -190 -2
-122 -3z"/>
<path d="M4919 4162 c5 -34 6 -35 9 -8 2 16 0 32 -5 37 -5 5 -7 -7 -4 -29z"/>
<path d="M5492 4193 c48 -2 129 -2 180 0 51 1 12 3 -87 3 -99 0 -141 -2 -93
-3z"/>
<path d="M1466 4128 c-5 -32 -10 -47 -15 -39 -6 10 -10 9 -14 -4 -6 -14 -33
-16 -252 -13 l-245 3 0 -37 c0 -21 -4 -38 -10 -38 -7 0 -10 -220 -10 -640 l0
-640 190 0 190 0 0 454 c0 257 4 457 9 460 14 9 21 -21 21 -93 0 -37 4 -103 9
-147 6 -43 20 -213 32 -376 l23 -298 278 0 278 0 0 619 c0 340 -3 645 -6 677
l-6 59 -160 -3 c-180 -4 -191 0 -186 56 2 22 -2 36 -10 38 -7 1 -8 0 -3 -2 6
-3 7 -12 4 -21 -9 -24 -10 -43 -11 -451 -1 -253 -5 -381 -12 -377 -5 3 -10 25
-10 47 0 23 -4 80 -9 127 -6 47 -15 149 -21 226 -7 77 -14 158 -16 180 -3 22
-9 93 -14 159 -5 65 -11 120 -13 122 -2 3 -7 -19 -11 -48z m12 -85 c-1 -43 -3
-61 -5 -40 -4 39 -18 49 -26 20 -3 -11 -5 -7 -6 10 -1 16 5 30 14 33 8 4 15
17 15 30 0 13 2 24 5 24 3 0 4 -35 3 -77z m114 3 c2 -19 0 -32 -4 -30 -8 5
-12 64 -4 64 2 0 6 -15 8 -34z m5 -88 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3
4 -12 1 -19z m-130 -50 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m20 -10 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M2232 4160 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4380 4144 c0 -19 4 -33 8 -30 8 5 4 66 -4 66 -2 0 -4 -16 -4 -36z"/>
<path d="M5336 4171 c-3 -4 6 -6 19 -3 14 2 25 6 25 8 0 8 -39 4 -44 -5z"/>
<path d="M5818 4173 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2728 4143 c5 -21 7 -23 10 -9 2 10 0 22 -6 28 -6 6 -7 0 -4 -19z"/>
<path d="M2752 4140 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5240 4122 c-14 -20 -29 -40 -34 -44 -12 -8 -24 -399 -13 -458 20
-118 66 -160 262 -236 82 -32 157 -66 167 -76 16 -16 18 -38 18 -187 0 -180
-5 -200 -50 -186 -19 6 -20 14 -20 176 l0 169 -195 0 -195 0 0 -150 c0 -218
22 -292 105 -355 63 -49 94 -54 317 -55 272 0 312 14 393 135 18 27 20 50 23
235 4 246 -3 292 -56 347 -44 44 -84 66 -247 129 -66 25 -126 52 -134 60 -12
10 -16 54 -19 193 -4 176 -4 179 19 194 26 19 59 7 59 -21 0 -15 2 -15 10 -2
8 13 10 12 10 -2 0 -10 -4 -18 -9 -18 -5 0 -8 -66 -7 -147 l1 -148 188 -3 188
-2 -2 162 c-1 90 -6 179 -9 200 -8 39 -38 94 -47 84 -3 -3 -1 -11 4 -18 6 -7
11 -20 11 -28 0 -8 3 -27 6 -42 6 -26 4 -28 -25 -28 -27 0 -30 2 -19 15 7 8
10 22 6 30 -3 8 -1 15 4 15 6 0 10 7 10 16 0 12 -5 15 -22 9 -13 -4 -53 -8
-90 -9 -55 -1 -70 -5 -82 -21 -15 -20 -15 -20 -36 3 -21 22 -50 30 -50 13 0
-5 7 -14 15 -21 22 -18 18 -47 -6 -53 -12 -3 -19 -3 -16 0 3 3 -3 14 -13 25
-19 20 -45 21 -92 3 -10 -4 -18 -3 -18 3 0 23 83 30 108 10 7 -6 16 -8 19 -4
4 4 1 11 -6 15 -7 5 -11 13 -8 18 3 4 -12 8 -34 8 -21 0 -39 -2 -39 -6 0 -3
-15 -10 -34 -16 -28 -9 -33 -8 -29 3 2 7 12 15 21 17 14 3 -239 11 -300 9 -23
-1 -23 7 3 34 12 12 20 27 18 33 -2 6 -15 -6 -29 -27z m437 -174 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M3613 4130 c4 -14 7 -31 7 -37 0 -7 4 -13 9 -13 6 0 6 12 1 31 -11
38 -26 55 -17 19z"/>
<path d="M5935 4140 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2776 4121 c-5 -7 -3 -18 3 -24 15 -15 13 -35 -5 -72 l-14 -30 5 28
c7 34 -4 82 -16 70 -5 -5 -10 -27 -11 -50 -1 -30 -5 -40 -15 -36 -7 3 -13 18
-13 34 l0 29 -230 0 c-230 0 -231 0 -239 23 -7 19 -10 14 -20 -33 -6 -30 -15
-89 -21 -130 -6 -41 -15 -97 -20 -125 -12 -62 -48 -286 -54 -340 -3 -22 -10
-67 -15 -100 -23 -136 -47 -292 -61 -390 -18 -128 -31 -209 -36 -231 l-6 -24
191 0 c220 0 196 -15 209 130 l7 85 91 3 91 3 11 -70 c7 -38 12 -88 12 -110
l0 -41 196 0 c181 0 195 1 190 18 -3 9 -12 67 -21 127 -9 61 -20 135 -25 165
-5 30 -13 87 -19 125 -62 410 -66 433 -85 540 -8 44 -17 100 -20 125 -9 71
-12 81 -24 89 -6 4 -8 12 -3 20 5 7 5 29 0 50 -5 20 -9 54 -9 76 -2 51 -13 84
-24 66z m-552 -170 c-4 -17 -9 -30 -11 -27 -3 2 -2 19 2 36 9 41 17 33 9 -9z
m-7 -83 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m308 -58 c4 -6
9 -50 11 -98 2 -48 8 -145 14 -217 11 -146 24 -326 25 -339 0 -4 -29 -9 -64
-10 l-64 -1 6 110 c3 61 11 184 18 275 7 91 13 190 13 220 1 50 9 69 28 70 4
0 10 -5 13 -10z"/>
<path d="M2496 3413 c-4 -65 -9 -144 -13 -175 l-6 -58 32 0 c26 0 31 3 27 18
-2 9 -8 88 -12 175 -3 86 -10 157 -14 157 -4 0 -10 -53 -14 -117z"/>
<path d="M4935 4056 c7 -70 -3 -69 -15 1 -7 42 -8 44 -9 14 -1 -33 -2 -34 -16
-15 -14 18 -14 17 -9 -12 8 -43 -11 -51 -32 -14 -23 38 -34 38 -34 0 0 -16 -4
-30 -10 -30 -5 0 -10 16 -10 35 l0 35 -154 0 c-146 0 -155 -1 -161 -20 -8 -24
-25 -17 -25 11 0 10 -4 19 -10 19 -5 0 -10 -16 -10 -35 0 -19 -4 -35 -10 -35
-5 0 -10 15 -10 34 0 20 -6 36 -14 39 -11 4 -13 -3 -11 -36 l3 -42 -12 50 -13
50 -7 -60 c-4 -33 -11 -76 -16 -95 -5 -19 -10 -53 -11 -75 -2 -22 -6 -44 -10
-50 -4 -5 -9 -37 -12 -70 -3 -33 -11 -85 -17 -115 -5 -30 -15 -89 -20 -130 -6
-41 -14 -97 -19 -125 -5 -27 -16 -102 -25 -165 -10 -63 -30 -197 -47 -297 -16
-99 -29 -186 -29 -192 0 -8 59 -11 189 -11 l190 0 6 43 c3 23 9 71 13 107 l7
65 91 3 91 3 13 -111 14 -110 189 0 190 0 -6 53 c-4 28 -11 79 -16 112 -5 33
-23 150 -40 260 -45 287 -79 509 -93 590 -6 39 -12 79 -14 90 -13 91 -35 217
-43 250 -10 37 -10 36 -6 -19z m-558 -68 c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23
2 -10 2 -28 0 -40z m530 20 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m-510 -30 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-10 -50
c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-20 -10 c-3 -7 -5 -2
-5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m307 -106 c2 -4 7 -57 11 -117 3 -61
8 -130 10 -155 9 -108 26 -383 23 -385 -2 -2 -30 -5 -63 -6 l-60 -3 3 65 c8
195 40 586 49 602 6 9 21 9 27 -1z"/>
<path d="M4647 3448 c-4 -68 -10 -156 -14 -196 l-6 -74 29 4 c24 2 29 7 27 29
-17 268 -23 353 -26 356 -2 2 -7 -52 -10 -119z"/>
<path d="M4838 4073 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5604 3820 c0 -91 2 -128 3 -82 2 45 2 119 0 165 -1 45 -3 8 -3 -83z"/>
<path d="M5604 3120 c0 -91 2 -128 3 -82 2 45 2 119 0 165 -1 45 -3 8 -3 -83z"/>
<path d="M6944 4210 c22 -6 46 -15 53 -22 7 -6 16 -9 18 -6 11 11 -23 27 -65
32 l-45 6 39 -10z"/>
<path d="M6468 4193 c84 -2 219 -2 300 0 81 1 12 3 -153 3 -165 0 -231 -2
-147 -3z"/>
<path d="M7520 4193 c211 -7 408 -7 403 0 -2 4 -113 6 -246 5 -133 -1 -204 -3
-157 -5z"/>
<path d="M6928 4173 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M7030 4163 c8 -3 29 -19 47 -36 27 -26 32 -36 28 -65 -4 -29 -2 -32
8 -21 6 8 11 12 10 10 -2 -2 1 -9 6 -15 4 -6 11 -20 14 -31 6 -17 5 -18 -6 -5
-15 18 -37 24 -37 10 0 -5 -4 -10 -9 -10 -5 0 -9 23 -7 50 1 28 -2 50 -7 50
-4 0 -5 -5 -1 -12 5 -8 0 -9 -14 -6 -22 6 -30 -6 -12 -17 16 -10 12 -65 -5
-65 -10 0 -15 10 -15 34 0 30 -4 34 -31 40 -17 3 -56 6 -86 6 l-55 0 4 -40 c3
-35 1 -40 -18 -40 -13 0 -24 8 -27 20 -3 11 -9 20 -14 19 -25 -4 -53 2 -48 11
6 10 23 8 63 -7 7 -3 12 3 12 14 0 17 -7 19 -60 19 -46 -1 -57 -4 -45 -12 21
-16 19 -27 -4 -19 -10 3 -24 -1 -31 -10 -7 -8 -17 -12 -22 -9 -5 3 -2 14 8 25
16 17 16 19 1 19 -10 0 -17 -6 -17 -14 0 -8 -5 -18 -11 -22 -9 -5 -10 1 -5 20
l6 26 -159 0 c-118 0 -161 3 -164 13 -3 6 -6 -300 -6 -680 l-1 -693 315 0
c352 0 367 2 439 69 21 20 48 56 60 81 20 44 21 58 24 577 3 455 1 540 -12
584 -19 64 -79 139 -109 138 -15 0 -17 -2 -7 -6z m-275 -139 c8 -4 22 -22 30
-41 18 -42 18 -46 5 -38 -7 4 -10 -161 -10 -489 0 -537 1 -526 -54 -526 l-23
0 -6 535 c-3 294 -4 542 -1 550 6 16 31 19 59 9z m-88 -20 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M7400 4120 c0 -16 2 -30 4 -30 2 0 6 14 8 30 3 17 1 30 -4 30 -4 0
-8 -13 -8 -30z"/>
<path d="M7930 4141 c0 -5 5 -13 10 -16 6 -3 10 -16 10 -28 0 -16 -3 -18 -11
-10 -16 16 -39 -8 -39 -42 0 -17 -4 -24 -12 -19 -8 5 -9 2 -5 -10 4 -10 3 -15
-3 -11 -5 3 -12 22 -16 41 -6 30 -10 35 -28 30 -11 -3 -87 -6 -167 -6 -134 0
-147 -2 -152 -19 -2 -10 -1 -25 4 -33 7 -12 0 -15 -36 -16 -49 0 -49 0 -36 43
6 22 4 34 -6 44 -20 18 -25 9 -13 -23 6 -18 6 -25 0 -21 -6 3 -10 13 -10 21 0
17 -19 19 -25 2 -5 -12 -11 -47 -34 -198 -6 -36 -15 -92 -21 -125 -33 -200
-78 -491 -120 -770 -17 -110 -33 -212 -36 -227 l-6 -28 190 0 190 0 11 50 c6
28 11 71 11 95 0 74 1 75 100 75 l89 0 5 -45 c3 -25 9 -66 12 -92 10 -90 -8
-83 205 -83 l189 0 -5 23 c-6 28 -74 462 -85 547 -10 77 -56 362 -70 440 -6
30 -15 91 -20 135 -21 157 -45 278 -57 283 -7 2 -13 -1 -13 -7z m30 -90 c0
-14 2 -44 5 -66 3 -26 -1 -19 -10 20 l-14 60 4 -75 4 -75 -12 70 c-14 78 -13
92 8 92 9 0 15 -10 15 -26z m-537 -33 c6 -4 8 -10 4 -15 -12 -11 -27 18 -25
47 l1 25 4 -25 c3 -14 10 -28 16 -32z m-26 -50 c-3 -7 -5 -2 -5 12 0 14 2 19
5 13 2 -7 2 -19 0 -25z m301 -160 c5 -7 11 -61 11 -119 1 -58 6 -120 10 -137
5 -17 7 -32 5 -35 -5 -4 14 -313 21 -344 5 -22 3 -23 -65 -23 l-70 0 5 38 c8
66 15 154 21 282 3 69 8 128 10 131 2 3 4 50 5 105 1 54 3 102 6 107 7 11 29
8 41 -5z"/>
<path d="M7666 3410 c-4 -63 -9 -141 -12 -172 l-6 -58 31 0 c30 0 31 1 26 38
-2 20 -7 88 -10 150 -9 164 -21 181 -29 42z"/>
<path d="M7491 4054 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8898 4213 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8070 4183 c0 -13 3 -14 8 -6 6 10 105 13 418 16 l409 2 -417 3
c-392 2 -418 1 -418 -15z"/>
<path d="M8072 4120 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M8899 4103 l-1 -28 -8 25 -8 25 -1 -27 c-1 -18 -7 -27 -18 -27 -10 0
-33 -3 -50 -7 -21 -4 -33 -2 -33 5 0 7 -8 7 -27 -2 -21 -9 -27 -9 -31 1 -6 19
-42 15 -42 -6 0 -25 -26 -59 -37 -48 -4 5 -2 11 5 13 6 3 11 17 11 31 -1 20
-3 22 -6 8 -3 -11 -8 -14 -12 -8 -3 6 -74 11 -179 13 -94 2 -166 2 -160 0 13
-4 28 -21 28 -33 0 -3 -9 -4 -21 -1 -16 4 -17 8 -7 14 9 6 7 9 -9 9 -12 0 -24
6 -27 13 -5 10 -7 10 -12 0 -7 -16 -58 -17 -86 -2 -14 8 -28 7 -50 -2 -24 -10
-31 -10 -38 0 -5 10 -9 8 -14 -4 -14 -38 -9 -40 120 -40 l125 0 -1 -652 -1
-653 191 0 191 0 -2 653 -2 652 127 0 126 0 -6 50 c-9 65 -17 74 -13 13 3 -27
1 -48 -3 -48 -9 0 -11 20 -8 63 1 15 -1 27 -4 27 -3 0 -6 -12 -7 -27z m-91
-53 c53 0 81 4 77 10 -3 6 -1 10 4 10 6 0 11 -7 11 -15 0 -13 -16 -16 -105
-14 -58 1 -105 5 -105 8 0 15 14 21 24 11 5 -5 48 -10 94 -10z m-530 -4 c3 -3
-43 -6 -101 -6 -59 0 -107 3 -107 8 0 8 199 6 208 -2z m389 -108 c-2 -13 -4
-3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z"/>
<path d="M9068 4213 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M9540 4213 c14 -3 28 -10 32 -17 5 -7 8 -5 8 7 0 13 -8 17 -32 16
-28 -2 -29 -2 -8 -6z"/>
<path d="M9185 4193 c172 -7 345 -7 345 0 0 4 -93 6 -207 5 -115 -1 -176 -3
-138 -5z"/>
<path d="M9051 4174 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M9598 4089 c-6 -15 -34 -17 -271 -15 -197 1 -263 -2 -260 -11 2 -6
-4 -31 -15 -54 -19 -40 -21 -42 -25 -19 -2 14 -3 3 -1 -25 2 -32 0 -45 -5 -36
-7 10 -11 0 -16 -35 -4 -27 -14 -87 -22 -134 -8 -47 -18 -112 -23 -145 -5 -33
-18 -118 -30 -190 -11 -71 -25 -159 -30 -195 -5 -36 -19 -126 -30 -200 -12
-74 -25 -164 -30 -200 -6 -36 -12 -75 -15 -87 l-5 -23 189 0 c104 0 192 4 195
8 3 5 8 44 11 88 11 134 2 124 105 124 102 0 95 7 105 -110 10 -121 -11 -110
206 -110 177 0 190 1 185 18 -3 9 -12 67 -20 127 -9 61 -20 137 -25 170 -5 33
-15 94 -21 135 -6 41 -15 100 -20 130 -5 30 -16 105 -25 165 -9 61 -23 148
-32 195 -9 47 -20 114 -24 150 -5 36 -11 79 -14 95 -5 27 -13 86 -14 109 -1 5
-8 3 -16 -4 -17 -14 -21 0 -4 17 6 6 9 26 7 44 -2 24 -5 29 -10 18z m-478 -75
c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m-92
-169 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m312 -42 c1 -10
5 -76 10 -148 5 -71 14 -199 20 -283 5 -85 12 -169 16 -187 l6 -33 -66 -4 -66
-3 0 45 c0 25 4 95 9 155 6 61 15 189 22 285 6 96 13 178 16 183 8 14 33 7 33
-10z"/>
<path d="M9306 3393 c-4 -65 -9 -139 -12 -165 l-6 -48 32 0 32 0 -6 58 c-3 31
-8 105 -12 165 -4 59 -10 107 -14 107 -4 0 -11 -53 -14 -117z"/>
<path d="M9031 4044 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
